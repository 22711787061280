import _index2 from "../assign/index.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cloneObject;

var _index = _interopRequireDefault(_index2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function cloneObject(object) {
  return (0, _index.default)({}, object);
}

exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;